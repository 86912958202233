<template>
  <page-container title="软件中心" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备模块类型" prop="devicemoduletype">
              <a-select v-model="queryParams.devicemoduletype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in devicemoduletypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="版本号" prop="version">
              <a-input v-model.trim="queryParams.version" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">版本信息列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceversionid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="terminaltypeid" slot-scope="text">
            <span class="text" :title="getTerminalTypeStr(text)">{{getTerminalTypeStr(text)}}</span>
          </span>
          <span slot="devicemoduletype" slot-scope="text">
            <span class="text" :title="devicemoduletypesMap[text]||''">{{devicemoduletypesMap[text]||''}}</span>
          </span>
          <span slot="uploadtime" slot-scope="text">
            <span :title="text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">{{text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" v-if="showDelete" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :terminaltypes="terminaltypes" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import devicemoduletypes from '@/json/devicemoduletypes'
import { getTerminaltypeListByCondition, getDeviceversionListByCondition, deleteDeviceversion } from 'A/ai'
import addOrEditModal from './addOrEditModal'
import {getCache} from "U/index";
export default {
  name:'softManagement',
  mixins: [pagination],
  components: {
    addOrEditModal
  },
  data() {
    return {
      moment,
      userid:'',
      showDelete:false,
      showAdvanced: false,
      terminaltypes: [],
      devicemoduletypes,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控硬终端',
          path: ''
        },
        {
          name: '软件中心',
          path: ''
        },
      ],
      queryParams: {
        devicemoduletype: '',
        version: '',
      },
      tableColumns: [
        {
          title: '监测设备型号',
          dataIndex: 'terminaltypeid',
          key: 'terminaltypeid',
          ellipsis: true,
          width:150,
          scopedSlots: { customRender: 'terminaltypeid' }
        },
        {
          title: '设备类型',
          dataIndex: 'devicemoduletype',
          key: 'devicemoduletype',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'devicemoduletype' }
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '版本文件',
          dataIndex: 'filename',
          key: 'filename',
          ellipsis: true,
        },
        {
          title: '版本描述',
          dataIndex: 'versiondesc',
          key: 'versiondesc',
          ellipsis: true,
        },
        {
          title: '更新时间',
          dataIndex: 'uploadtime',
          key: 'uploadtime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'uploadtime' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
    devicemoduletypesMap() {
      let result = {};
      devicemoduletypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  created() {
    this.init();
    this.getTerminaltypes();
    // this.getTableData();
  },
  methods: {
    init(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.userid = logininfo.usersid;
        if(this.userid=='1'){
          this.showDelete=true
        }
      }
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: ''+item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
        this.terminaltypes.unshift({
          value: '0',
          text: '所有模块'
        })
        this.getTableData();
      }).catch(err => {
        this.getTableData();
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      // this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      // if(params.devicemoduletype==''&&params.version==''){
      //   getDeviceversionListByCondition(params).then(res => {
      //     this.tableLoading = false;
      //     if(res && res.returncode == '0') {
      //       this.tableData = res.item;
      //       this.counts = res.count;
      //     }
      //   }).catch(err => {
      //     this.tableLoading = false;
      //   })
      // }
      getDeviceversionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total= res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.deviceversionid);
      }).catch(()=>{
      });
    },
    delete(deviceversionid) {
      this.showLoading();
      if(deviceversionid) {
        let params = {
          deviceversionid
        };
        deleteDeviceversion(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    getTerminalTypeStr(text) {
      let result = [];
      if(text) {
        let typeArr = text.split(',');
        typeArr.forEach(item => {
          if(this.terminaltypesMap[item]) {
            result.push(this.terminaltypesMap[item]);
          }
        })
      }
      return result.join(',')
    }
  }
}
</script>