<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="设备型号" prop="terminaltypeid">
        <a-select v-model="formDatas.terminaltypeid" :disabled="showType=='detail'">
          <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备模块类型" prop="devicemoduletype">
        <a-select v-model="formDatas.devicemoduletype" :disabled="showType=='detail'">
          <a-select-option v-for="(item, index) in devicemoduletypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="版本号" prop="version">
        <a-input v-model.trim="formDatas.version" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="版本文件" prop="filename">
        <a-upload :disabled="showType=='detail'" name="fileList" :headers="uploadHeaders" :data="{folder: this.formDatas.devicemoduletype||'1'}" action="/uploadDevice" :file-list="filenameList" @change="filenameUploaded">
          <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="versiondesc">
        <a-input v-model.trim="formDatas.versiondesc" :disabled="showType=='detail'" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import devicemoduletypes from '@/json/devicemoduletypes'
import { addDeviceversion, modifyDeviceversion } from 'A/ai'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    terminaltypes: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      devicemoduletypes,
      modalVisible: false,
      formDatas: {
        deviceversionid: '',
        terminaltypeid: '',
        devicemoduletype: '',
        version: '',
        filename: '',
        versiondesc: '',
      },
      filenameList: [],
      formRules: {
        terminaltypeid: [{required: true, message: '请选择设备型号'}],
        devicemoduletype: [{required: true, message: '请选择设备模块类型'}],
        version: [{required: true, message: '请输入版本号'}],
        filename: [{required: true, message: '请上传版本'}],
        versiondesc: [{required: true, message: '请输入版本描述'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
    ...mapGetters(['uploadHeaders']),
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = ''+this.detailData[key]||'';
          }
          if(this.formDatas.filename) {
            let nameIndex = this.formDatas.filename.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.filename.lastIndexOf('\\');
            }
            let fileName = this.formDatas.filename.substr(nameIndex+1);
            this.filenameList = [{
              uid: 'filenameuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.filename,
            }];
          }
        })
      }
    },
    resetDatas() {
      this.formDatas.deviceversionid = '';
      this.$refs.modalForm.resetFields();
      this.filenameList = [];
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addDeviceversion(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyDeviceversion(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    filenameUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.filename = file.response.urlPath;
        }
        return file;
      });
      if(fileList.length == 0) {
        this.formDatas.filename = '';
      }
      this.filenameList = fileList;
    },
  }
}
</script>